<template>
  <div>
    <div class="stepBarWrapper">
      <div
        :class="currentPage > i ? 'active' : 'gray'"
        v-for="(page, i) in pageAmount"
        :key="i"
        class="pageDot"
      ></div>
      <div class="pageLinePlaceholder"></div>
      <div :style="getWidth" class="pageLineCurrent"></div>
    </div>
    <div class="stepBarText">
      <div v-for="(title, i) in titles" :key="i" class="title">
        <span>{{ title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['pageAmount', 'currentPage', 'titles'],
  computed: {
    getWidth() {
      let wid = 100 * ((this.currentPage - 1) / (this.pageAmount - 1))
      return { width: wid + '%' }
    },
  },
}
</script>

<style scoped lang="scss">
.stepBarText {
  width: 100%;
  display: flex;
  margin: 10px auto;
  justify-content: space-between;
  text-align: center;
  position: relative;
}

.title {
  position: relative;
  width: 25px;
  height: 25px;
}

.title span {
  font-size: 9pt;
  white-space: pre;
  display: flex;
  justify-content: center;
}

.title:nth-child(1) span {
  justify-content: flex-start;
}

.title:last-child span {
  justify-content: flex-end;
}

.stepBarWrapper {
  width: 99%;
  display: flex;
  margin: auto;
  justify-content: space-between;
  position: relative;
}

.pageDot {
  z-index: 3;
  position: relative;
  width: 25px;
  height: 25px;
  background: $accent;
  border: 5px solid rgb(241, 241, 241);
  border-radius: 50%;
}

.pageLinePlaceholder {
  z-index: 1;
  width: 100%;
  height: 2px;
  background: #cecece;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.pageLineCurrent {
  z-index: 2;
  width: 0%;
  height: 2px;
  background: $accent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.active {
  background: $accent;
}

.gray {
  background: #b6b6b6;
}
@media only screen and (max-width: 1150px) {
  .title span {
    font-size: 7pt;
    white-space: pre;
    display: flex;
    justify-content: center;
  }
}
</style>