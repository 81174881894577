<template>
  <div @click="closeBox($event)" class="popupBox">
    <div class="inputWrapper">
      <div>
        <p>Name</p>
        <input type="text" placeholder="Per" v-model="name" />
      </div>
      <div>
        <p>Telephone</p>
        <input type="text" placeholder="070-123 45 67" v-model="phone" />
      </div>
      <div>
        <span><i class="fas fa-plus-circle"></i></span
        ><button @click="addCustomer">Add customer</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["editShowAddNewCustomer", "customerNameQuery"],
  data() {
    return {
      name: this.customerNameQuery,
      phone: ''
    };
  },
  methods: {
    closeBox(event) {
      if (event.target.classList[0] === "popupBox") {
        this.editShowAddNewCustomer("");
      }
    },
    addCustomer() {
      axios
        .post("customers/addNewCustomer", {
          companyId: this.$route.params.companyId,
          customerName: this.name,
          customerTelephone: this.phone,
        })
        .then((res) => {
          if (res.data) {
            this.editShowAddNewCustomer(this.name, res.data.insertId, this.phone);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.popupBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
}
.inputWrapper {
  border-radius: 5px;
  width: 300px;
  padding: 20px;
  margin: auto;
  text-align: center;
  justify-content: center;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  background: white;
}

.inputWrapper div {
  display: inline-flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

.inputWrapper div p {
  font-size: 10pt;
  color: #888;
  margin-right: 10px;
}

.inputWrapper input {
  transition: 0.5s;
  border: none;
  border-bottom: 1px solid #eee;
  font-size: 8pt;
  height: 30px;
}

.inputWrapper div span {
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: white;
  border-radius: 5px 0 0 5px;
  background: rgb(13, 104, 28);
}

.inputWrapper div button {
  border: none;
  color: white;
  border-collapse: none;
  font-size: 10pt;
  background: rgb(18, 143, 39);
  border-radius: 0 5px 5px 0;
}

.inputWrapper input:focus {
  border-bottom: 1px solid rgb(79, 30, 255);
}
</style>