<template>
  <div>
    <p class="mediumTitle">Delivery information</p>

    <!-- Date -->
    <div class="inputBlock">
      <p class="smallTitle">Date of delivery</p>
      <div class="inputs date">
        <div>
          <span>Month</span
          ><input
            type="text"
            v-model="orderInformation.dateMonth"
            name="month"
            placeholder="mm"
          />
          <p v-if="!isMobile">/</p>
        </div>
        <div>
          <span>Day</span
          ><input
            type="text"
            v-model="orderInformation.dateDay"
            name="day"
            placeholder="dd"
          />
          <p v-if="!isMobile">/</p>
        </div>
        <div>
          <span>Year</span
          ><input
            type="text"
            v-model="orderInformation.dateYear"
            name="year"
            placeholder="yyyy"
          />
        </div>
        <div>
          <VueDatePicker noHeader ref="start" class="dateIcon" v-model="date" placeholder="">
            <template #input-icon>
              <!-- <ion-icon name="calendar-outline"></ion-icon> -->
              <i class="far fa-calendar-alt"></i>
            </template>
          </VueDatePicker>
        </div>
      </div>
    </div>

    <!-- Pickup -->
    <div class="inputBlock">
      <p class="smallTitle">Pickup/delivery time</p>
      <div class="inputs deliveryTime">
        <div>
          <span>Select start time</span
          ><input
            v-model="orderInformation.pickupDeilveryTimeStart"
            type="time"
            name="startTime"
          />
          <p v-if="!isMobile"></p>
        </div>
        <div>
          <span>Select end time</span><span class="optional">Optional</span
          ><input
            v-model="orderInformation.pickupDeilveryTimeEnd"
            type="time"
            name="endTime"
          />
          <p v-if="!isMobile"></p>
        </div>
      </div>
    </div>

    <!-- Delivery Method -->
    <div class="inputBlock">
      <p class="smallTitle">Pickup/delivery method</p>
      <div class="inputs custom1">
        <div>
          <input
            type="radio"
            v-model="orderInformation.pickupDeliveryMethod"
            id="in_shop"
            value="in_shop"
            name="deliveryMethod"
          /><label for="in_shop">Customer pickup in store</label>
          <p v-if="!isMobile"></p>
        </div>
        <div>
          <input
            type="radio"
            v-model="orderInformation.pickupDeliveryMethod"
            id="hemleverans"
            value="hemleverans"
            name="deliveryMethod"
          /><label for="hemleverans">Home delivery</label>
          <p v-if="!isMobile"></p>
        </div>
        <div>
          <input
            type="radio"
            v-model="orderInformation.pickupDeliveryMethod"
            id="drive_through"
            value="drive_through"
            name="deliveryMethod"
          /><label for="drive_through">Drive through</label>
          <p v-if="!isMobile"></p>
        </div>
      </div>
    </div>

    <!-- Store -->
    <div class="inputBlock">
      <p class="smallTitle">Store</p>
      <div class="inputs custom1 ">
        <div v-for="(shop, i) in shops" :key="i">
          <input
            ref="stores"
            v-model="orderInformation.store"
            :value="shop.id"
            type="radio"
            :id="shop.slug"
            name="store"
            class="custom-radio"
          /><label :for="shop.slug">{{ shop.locationName }}</label>
          <p v-if="!isMobile"></p>
        </div>
      </div>
    </div>

    <!-- Payment -->
    <div class="inputBlock">
      <p class="smallTitle">Payment method</p>
      <div class="inputs custom1 payment">
        <div>
          <input
            type="radio"
            v-model="orderInformation.paymentMethod"
            value="swish"
            id="swish"
            name="payment"
          />
          <label for="swish"
            ><img
            style="width:70px"
              src="https://www.logo.wine/a/logo/Swish_(payment)/Swish_(payment)-Logo.wine.svg"
              alt="Swish"
          /></label>
        </div>
        <div>
          <input
            type="radio"
            v-model="orderInformation.paymentMethod"
            value="in_store"
            id="in_store"
            name="payment"
          />
          <label for="in_store">
            In store
          </label>
        </div>
      </div>
    </div>

    <!-- Note -->
    <div class="inputBlock">
      <p class="smallTitle">Note</p>
      <div class="inputs note">
        <div>
          <span
            >For example their preferred way of contact or customer delivery
            requests.</span
          ><span class="optional">Optional</span
          ><textarea v-model="orderInformation.note"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  props: ['updateDateValues', 'orderInformation', 'shops'],
  data() {
    return {
      date: undefined,
      startElement: undefined,
      storeElement: undefined
    }
  },
  components: {
    VueDatePicker,
    VueTimepicker
  },
  created() {

  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
  },
  watch: {
    date() {
      this.updateDateValues(this.date)
      this.date = undefined
    },
  },
}
</script>

<style scoped lang="scss">
/* Global */
.dateIcon {
  margin-left: 10px;
  font-size: 20pt !important;
}
.inputs ion-icon {
  font-size: 20pt;
  display: flex;
  position: relative;
  margin: auto 10px;
}
.inputs {
  display: flex;
  position: relative;
  margin-top: 20px;
}
.inputs div p {
  font-size: 18pt;
  position: relative;
  display: flex;
  margin: auto 10px;
}
.inputs div span {
  position: absolute;
  z-index: 10;
  top: -20px;
}
.inputs div {
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
  color: #7a7a7a;
  position: relative;
  font-size: 8pt;
}

.inputs input {
  border: 1px solid #e3e3e3;
  background: #f3f3f3;
  position: relative;
  margin: auto;
  padding: 5px;
  color: rgb(95, 95, 95);
  width: 100px;
  display: block;
  box-sizing: content-box;
  border-radius: 5px;
  height: 20px;
}
.inputBlock {
  margin-top: 20px;
  position: relative;
}
.smallTitle {
  font-size: 11pt;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #444444;
}
.mediumTitle {
  font-size: 15pt;
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
  color: #444444;
}
.optional {
  position: absolute;
  right: 0;
  transform: translateX(-50%);
  top: -20px;
  font-style: italic;
}
.inputs label {
  margin: 0 5px;
}
.inputs img {
  height: 30px;
  margin: 0 5px;
}
.inputs textarea {
  width: 100%;
  min-height: 100px;
  max-height: 300px;
  border: 1px solid #e3e3e3;
  background: #f3f3f3;
  position: relative;
  margin-top: 5px;
  padding: 5px;
  color: rgb(95, 95, 95);
  display: block;
  box-sizing: content-box;
  border-radius: 5px;
  min-width: 100%;
}

/* Custom inputs */
.deliveryTime input {
  width: 150px;
}

.custom1 input {
  width: auto;
  margin: 0;
}
.custom1 {
  margin-top: 3px;
}
.custom1 div {
  width: 200px;
}
.payment input {
  position: relative;
  display: flex;
  margin: auto 0;
}
.note .optional {
  transform: translateX(0);
}
.note div {
  width: 100%;
  position: relative;
}

.inputBlock:last-child {
  margin-bottom: 20px;
}

input[type="radio"]:focus + label {
  background-color: none;
  border-bottom: 1px solid $accent;
}

@media only screen and (max-width: 1000px) {
  .inputs {
    margin-top: 0;
  }
  .inputs div {
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    color: #7a7a7a;
    position: relative;
    margin: 5px 0;
    font-size: 8pt;
    display: flex;
    justify-content: space-between;
  }
  .inputs div span {
    position: relative;
    z-index: 10;
    top: 0px;
    line-height: 30px;
  }
  .inputs input {
    margin: 0;
    border: 1px solid #e3e3e3;
    background: #f3f3f3;
    position: relative;
    padding: 5px;
    color: rgb(95, 95, 95);
    width: 100px;
    display: block;
    box-sizing: content-box;
    border-radius: 5px;
    height: 20px;
  }
  .inputs {
    display: block;
    position: relative;
    margin-top: 20px;
  }
  .deliveryTime input {
    width: 75px !important;
  }
  .custom1 div {
    width: 100%;
  }
  .custom1 div input {
    width: auto;
  }
  .note div {
    display: grid;
  }
  .note div span {
    line-height: unset;
  }
}
</style>