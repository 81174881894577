<template>
  <div style="padding-bottom: 20px">
    <!-- Header -->
    <p class="mediumTitle">Search for a product</p>
    <Search
      ref="search"
      :dataFunction="getProductByName"
      :continueButton="continueButton"
      :returnFunction="addProduct"
      :addFromSearch="{display: false, func: undefined}"
      :displayKey="'productName'"
      :placeholder="'Search product'"
      :icon="'fa-fish'"
    />

    <!-- Added products -->
    <p>You've added {{ products.length }} products</p>
    <hr class="header" />
    <Product
      :ref="'productId' + product.id"
      :searchElement="searchElement"
      :currentElement="currentElement"
      v-for="(product, i) in reverseProducts"
      :key="product.id"
      :product="product"
      :index="i"
      :removeProduct="removeProduct"
    />
  </div>
</template>

<script>
import axios from 'axios'

import Search from '@/components/Search'
import Product from '@/components/orders/addOrder/Product'

export default {
  props: ['continueButton','products'],
  components: {
    Search,
    Product,
  },
  data() {
    return {
      currentFocus: undefined,
      currentElement: undefined,
      searchElement: undefined,
    }
  },
  created() {
    this.$nextTick((_) => {
      this.searchElement = this.$refs.search.$el
      this.searchElement.getElementsByTagName('input')[0].focus()
    })
  },
  computed: {
    reverseProducts() {
      let reversedProducts = []
      for (let index = (this.products.length - 1); index >= 0; index--) {
        this.products[index].realIndex = index;
        reversedProducts.push(this.products[index])
      }
      return reversedProducts
    }
  },
  methods: {
    addProduct(data) {
      this.currentFocus = data.id
      data.productAmount = 1
      data.productUnit = 'st'
      data.productUnit = 'undefined'
      this.products.push(data)
      this.$nextTick((_) => {
        this.searchElement = this.$refs.search.$el
        this.currentElement = this.$refs[String('productId' + this.currentFocus)][0].$el
        this.currentElement.getElementsByTagName('input')[0].focus()
      })
    },
    getProductByName(product) {
      return new Promise((resolve) => {
        axios
          .post('products/getProducts', {
            companyId: this.$route.params.companyId,
            searchString: product,
          })
          .then((res) => {
            resolve(res.data)
          })
      })
    },
    removeProduct(index) {
      this.products.splice(index, 1)
    },
  },
}
</script>

<style scoped lang="scss">
.header {
  margin-top: 20px;
  margin-bottom: 10px;
}
.smallTitle {
  font-size: 11pt;
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
  color: #444444;
}
.mediumTitle {
  font-size: 15pt;
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
  color: #444444;
}
</style>