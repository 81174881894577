<template>
  <div class="customerBlock" v-if="userData">
    <div class="customerHeader">
      <p>{{ userData.name }}</p>
    </div>
    <div class="customerSections">
      <div>
        <section>
          <p>Email</p>
          <span>{{ userData.email }}</span>
        </section>
        <section>
          <p>Address</p>
          <span>{{ userData.address }}</span>
        </section>
      </div>
      <div>
        <section>
          <p>Phone</p>
          <span>{{ userData.tel }}</span>
        </section>
        <section>
          <p>Zip</p>
          <span>{{ userData.zip }}</span>
        </section>
      </div>
      <div>
        <section>
          <p>Note</p>
          <span>No notes</span>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['userData', 'removeUser'],
}
</script>

<style scoped lang="scss">
.customerBlock {
  width: 100%;
  position: relative;
  font-family: 'Roboto', sans-serif;
  -webkit-box-shadow: 0px 0px 9px -4px rgba(163, 163, 163, 1);
  -moz-box-shadow: 0px 0px 9px -4px rgba(163, 163, 163, 1);
  box-shadow: 0px 0px 9px -4px rgba(163, 163, 163, 1);
  padding: 15px 25px;
  border-radius: 10px;
  background: white;
}

.customerBlock hr {
  margin: 15px 0;
}

.customerHeader {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 30px;
  margin: auto;
}

.customerHeader p {
  font-size: 13pt;
  font-weight: bold;
  line-height: 30px;
  color: #444444;
}

.customerSections {
  width: 100%;
  position: relative;
  display: flex;
}

.customerSections div {
  position: relative;
  width: 100%;
}
.customerSections div section {
  word-break: break-all;
  margin: 5px 0;
}

.customerSections div section p {
  color: $accent;
  font-weight: bold;
  font-size: 9pt;
}

.customerSections div section span {
  position: relative;
  color: #444444;
  width: calc(100% - 120px);
}

@media only screen and (max-width: 1000px) {
  .customerSections {
    display: inline;
  }
  .customerSections div:nth-child(1) {
    
  }
}
</style>