<template>
  <div class="product">
      <img :src="imgSrc" />
      <p>{{ product.productName }}</p>
      <input @keydown.enter="focusUnits" v-model="product.productAmount" step="0.1" type="number" min="0">
      <select @keydown.tab="focusSearch" @keydown.enter="focusSearch" v-model="product.productUnit">
        <option disabled value="undefined">Choose unit</option>
        <option value="st">Units</option>
        <option value="kilo">Kilo</option>
      </select>
      <p v-if="isMobile" @click="removeProduct(product.realIndex)" class="remove"><ion-icon name="trash-outline"></ion-icon></p>
      <p v-else @click="removeProduct(product.realIndex)" class="remove">Remove<ion-icon name="trash-outline"></ion-icon></p>
  </div>
</template>

<script>
import axios from 'axios'
import firebase from "firebase/app";
import { mapGetters } from 'vuex'
import 'firebase/storage'
let uuid = require("uuid");

export default {
  props: ["product", "removeProduct", "index", "currentElement", "searchElement"],
  data() {
    return {
      imgSrc: "https://i.stack.imgur.com/y9DpT.jpg",
    }
  },
  created() {
    this.getImages(this.product.id)
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
  },
  methods: {
    focusSearch() {
      this.$nextTick((_) => {
        this.searchElement.getElementsByTagName("input")[0].focus();
      })
    },
    focusUnits() {
      this.currentElement.getElementsByTagName("select")[0].focus();
    },
    getImages(productId) {
      axios
        .post("products/getImages", {
          productId: productId
        })
        .then(async res => {
          if (res.data.length > 0) {            
              try {
                var storageRef = firebase.storage().ref();
                var imageRef = storageRef.child(
                  `images/${res.data[0].imageName}.jpg`
                );
                let val = await imageRef.getDownloadURL();
                this.imgSrc = val
              } catch (error) {
                console.log(error);
              }
           
          }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.product {
  width: 100%;
  position: relative;
  margin: 5px 0;
  display: flex;
}
.product img {
  height: 75px;
  width: 125px;
  border-radius: 3px;
  object-fit: cover;
}
.product p {
  position: relative;
  display: flex;
  margin: auto 20px;
  font-family: 'Roboto', sans-serif;
  width: 140px;

}
.product input:focus, .product select:focus {
  border: 1px solid $accent;
}

.product input, .product select {
  border: 1px solid #E3E3E3;
  background: #F3F3F3;
  position: relative;
  margin: auto 5px;
  padding:5px;
  color: rgb(95, 95, 95);
  max-width: 100px;
  display: block;
  box-sizing:content-box;
  border-radius: 5px;
  height: 20px;
}
.product .remove {
  color: rgb(160, 0, 0);
  position: absolute;
  right: 0;
  font-size: 10pt;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}
.product .remove ion-icon {
  position: relative;
  top: 4px;
}
@media only screen and (max-width: 1100px) {
  .product {
    width: 100%;
  }
  .product img {
    display: none;
  }
  .product p {
    margin: 0 !important;
    width: 80px;
  }
  .product input, .product select {
    width: 50px;
  }
  .product .remove {
    position:absolute;
    float: right;
  }
  .product .remove ion-icon {
    position: absolute;
    right: 0;
    top: -8px;
  }
}
</style>